
.Login-layout-main{
    display: flex;
    flex-direction: column;
}
.Login-layout-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 80px 0 ;
}

.Login-logo {
    margin: 60px 0;
    font-size: 22px;
    font-weight: bold;
}
