/*


.ant-carousel .slick-slide {
  text-align: center;
  height: 180px;
  line-height: 180px;
  background: #FEFEFE;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
*/


.Home-layout-main{
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    align-items: center;
    margin: 20px;
}


.Home-login {
    position:absolute;
    right: 20px;
    top: 10px;
}

.Home-logo {
    font-size: 22px;
    margin: 80px 20px;
}