@import '../../node_modules/antd/dist/antd.css';


.search-list-header {
    border: 0 solid rgb(235, 237, 240) !important;
    background-color: white;
}
.ant-list-bordered .ant-list-header {
    padding-right: 0;
    padding-left: 0;
    background-color: white;
}
.Item-layout {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: white;
}

.Item-layout-content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.Item-layout-title {
    display: flex;
    flex-direction: column;
}
.Item-layout-price {
    color: black;
}
.Item-layout-price-after {
    color: grey;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 2px;
}
.Item-layout-price-end {
    color: red;
    font-size: 14px;
}
.Item-layout-price-master {
    color: red;
    font-size: 14px;
    margin-left: 20px;
}
.Item-layout-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
