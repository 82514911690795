@import '../../node_modules/antd/dist/antd.css';


.Reco-layout {
    display: flex;
    flex-direction: row;
    padding: 16px;
    background-color: white;
}

.Reco-layout-content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.Reco-layout-title {
    display: flex;
    flex-direction: column;
}
.Reco-layout-price {
    font-size: 12px;
    color: grey;
}
.Reco-layout-price-after {
    color: red;
    font-size: 12px;
}
.Reco-layout-price-end {
    color: red;
    font-size: 18px;
}
.Reco-layout-price-master {
    color: red;
    font-size: 14px;
    margin-left: 20px;
}
.Reco-layout-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
}
