
.pics_wrap{
  /*background-color: #202020E0;*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*opacity: 80%;*/
  /*filter: alpha(opacity=80)*/
}
/*

:global {
  .yourClass{
       .ant-table-thead > tr > th {
        background: #f5f7fa;
        color: #596268;
        font-size: 14px;
        font-weight: bold;
        cursor: default;
        text-align: left !important;
      }
  }

  .pic_preview {
      .ant-carousel .slick-slide {
          text-align: center;
          height: 400px;
          line-height: 400px;
          background: #FEFEFE;
          overflow: hidden;
      }
  }

}
*/
