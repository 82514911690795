

.Point_layout_main {
    display: flex;
    flex-direction: column;
    min-height: 600px;
}

.Point_layout_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 20px 20px 80px 20px;
}

.Point_layout_content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
}