

.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    background-color: white;
}

.content-bg {
    background-color: white;
    padding: 6px 20px;
    margin-top: 2px;
}
.Detail-layout {
    display: flex;
    flex: 100;
    flex-direction: column;
}
.Detail-layout2 {
    display: flex;
    flex-direction: column;
    background-color: #EFEFEF;
}


.Detail-shop{
    display: flex;
    flex-direction: row;
    color: grey;
    font-size: 12px;
    margin: 6px;
}

.Detail-layout-price{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    margin-top: 2px;
    padding: 20px 20px;
    background-color: white;
}
.Detail-price {
    color: black;
    margin-left: 10px;
    background-color: white;
}
.Detail-price-re {
    color: grey;
    margin-left: 10px;
    font-size: 14px;
    text-decoration: line-through
}
.Detail-price-end {
    color: red;
    margin-left: 10px;
    font-size: 16px;
}

.Detail-quan-tip {
    margin-top: 10px;
    margin-bottom: 6px;
    color: grey;
    font-size: 12px
}
